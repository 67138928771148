import './App.css';
import Datasheet from './components/Datasheet';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PageNotFound from './components/PageNotFound';
function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
  <Routes>
    <Route path="/" element={ <Datasheet/>} />
    <Route path="/404" element={ <PageNotFound/>} />
   
   
  </Routes>
</BrowserRouter>
    
    </div>
  );
}

export default App;
